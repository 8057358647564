import React from "react"
import Block from "../../core/components/block"
import Banner from "../../client/compositions/banner"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ContactUs from "../../client/compositions/contact-us"
import Row from "../../core/components/row"
import Sticky from "../../core/components/sticky"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Privacy Page
 * @see https://zpl.io/V4mAnJ4
 */
function PrivacyPage() {
  return (
    <article id="page-privacy" className="page-content">
      {quickMenu}

      <Banner
        title={
          <>
            Privacy
            <br /> Policy
          </>
        }
      />

      <Row id="terms" className="long-text">
        <Block className="block-content">
          <Sticky margin={40}>
            Updated: 15.05.2020
            <br />© Invinity
          </Sticky>
        </Block>
        <Block className="block-content">
          <p>
            InVinity takes great measures to ensure that all privileged
            information is respected and protected. We understand that clients
            entrust us with confidential material and we ensure that all
            information shared with InVinity is secure. InVinity prides itself
            in its dedication to maintaining the privacy of our clients and will
            not disclose any content sent to us to any third party. Moreover,
            clients can rest assured that any material sent to, or retrieved
            from, our website is done so through our secure system.
          </p>
          <p>
            InVinity reserves the right to amend this policy without prior
            notice, so please re-visit this page periodically. If you continue
            to visit our website and use our services after changes have been
            made, you hereby provide your consent to those changes.
          </p>
          <p>
            Our privacy policy is governed solely by applicable Virginia
            statutes and U.S. federal laws. Your use and access of the InVinity
            website constitutes your agreement to be bound by the provisions
            contained in this Privacy Policy no matter your location.
          </p>
          <h4 className="title">Personally Identifiable Information</h4>
          <p>
            InVinity.com uses first party cookies. Each time you visit
            InVinity.com through the use of cookies, our server automatically
            recognizes your domain name and email address (when possible). You
            are able to disable cookies on your browser, but this may affect
            your access to the InVinity website.
          </p>
          <p>
            Any personal identification information we collect is to ensure your
            continued satisfied use of our site and products. This data is only
            used when an inquiry is sent to us and/or an order is placed through
            our website. Users are not required to enter any personal
            information to visit our site, but will be prompted for certain
            information, such as name, contact information and credit card
            information, among others, in order to send in an inquiry form or
            place an order, which they choose to do voluntarily. We do not share
            this information with outside parties, except to provide the
            requested service.
          </p>
          <h4 className="title">Non-Personally Identifiable Information</h4>
          <p>
            Certain non-personally identifiable information about our visitors
            is collected through the use of ‘cookies’, including but not limited
            to your internet browser, domain name you are using, what pages you
            visit, the length of time you spend on certain pages. These data are
            used to enhance your user experience and help us improve our website
            as well as contact customers.
          </p>
          <h4 className="title">Protection for Children</h4>
          <p>
            This website is designed for use by those 21 or older. Anyone under
            the age of 21 is not authorized to submit any content or information
            to this website without the explicit consent of a parent or legal
            guardian. If a child under the age of 13 has submitted personal
            information to our site, a parent or legal guardian should contact
            us to have it removed.
          </p>
          <h4 className="title">Email Addresses</h4>
          <p>
            Email addresses entered by users for orders or inquiries will only
            be used to send the requested information related to the related
            service. We may use email addresses to send company updates and news
            about new products and services. Users can opt-out of our mailing
            list by sending us an email request or via a link at the bottom of
            the message.
          </p>
          <p>
            Persons who supply us with their telephone numbers on-line may
            receive telephone contact from us with information regarding orders
            or inquiries they have submitted on-line. They also may receive
            telephone contact from us with information regarding new products
            and services. If you do not wish to receive such telephone calls, or
            would like to be placed on our ‘do not share list’, please let us
            know by sending an email or calling us.
          </p>
          <h4 className="title">Security</h4>
          <p>
            We are committed to maintaining the security of your personal
            information. To prevent unauthorized access, maintain data accuracy,
            and ensure the appropriate use of information, we adhere to industry
            standard practices and procedures to help safeguard and secure the
            information we collect. Although we use our best efforts to protect
            the security of your personal information and our system, due to the
            inherent open nature of the Internet, no transmission via the
            Internet can be guaranteed to be 100% secure. As a result of this
            and other factors beyond our control, we cannot guarantee the
            security of the information that you transmit to or through our
            website. Therefore, you assume that risk by using the website.
          </p>
          <h4 className="title">Links to other websites</h4>
          <p>
            This Website may contain links to other websites. Please be aware
            that we are not responsible for the privacy practices of other
            websites. When you access any such third party links, please read
            the privacy policies of each website. This Privacy Policy only
            pertains to information collected by InVinity.
          </p>
          <h4 className="title">Contact us</h4>
          <p>
            If you have any questions, comments or concerns regarding this
            Privacy Policy and/or the practices described herein,
            <br /> please email us at
            <br />
            <br /> support@invinitywinerack.com
            <br />
            <br /> or call us at 1-855-378-1004.
          </p>
        </Block>
      </Row>

      <ContactUs>
        <DecorationFluid image="/privacy/contact.small.jpg" />
      </ContactUs>
      <Tagline />
    </article>
  )
}

export default PrivacyPage
